import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, HOME_PREFIX_PATH } from "configs/AppConfig";
import React from "react";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("views/auth-views/authentication/login"))
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import("views/auth-views/authentication/forgot-password"))
  }
];

export const protectedRoutes = [
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() => import("views/auth-views/authentication/reset-password")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "adminpage",
    path: `${APP_PREFIX_PATH}/adminpage`,
    component: React.lazy(() => import("views/app-views/adminpage")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "dashboard.faxes",
    path: `${APP_PREFIX_PATH}/dashboards/faxes`,
    component: React.lazy(() => import("views/app-views/dashboards/faxes"))
  },
  {
    key: "apps.checkin.scrumboard",
    path: `${APP_PREFIX_PATH}/apps/checkin/incomingRequest`,
    component: React.lazy(() => import("views/app-views/apps/checkin/scrumboard"))
  },
  {
    key: "apps.project.scrumboard",
    path: `${APP_PREFIX_PATH}/apps/project/incomingRequest`,
    component: React.lazy(() => import("views/app-views/apps/project/scrumboard"))
  },
  {
    key: "apps.referral.scrumboard",
    path: `${APP_PREFIX_PATH}/apps/referral/incomingRequest`,
    component: React.lazy(() => import("views/app-views/apps/referral/scrumboard"))
  },
  {
    key: "apps.appointment.details.list",
    path: `${APP_PREFIX_PATH}/apps/appointmentDetails/list`,
    component: React.lazy(() => import("views/app-views/apps/appointmentDetails/project-list/ProjectList"))
  },
  {
    key: "apps.faxes",
    path: `${APP_PREFIX_PATH}/apps/faxes`,
    component: React.lazy(() => import("views/app-views/apps/faxes/faxes"))
  },
  {
    key: "apps.search",
    path: `${APP_PREFIX_PATH}/apps/search`,
    component: React.lazy(() => import("views/app-views/apps/search/search"))
  },
  {
    key: "apps.sms",
    path: `${APP_PREFIX_PATH}/apps/sms/*`,
    component: React.lazy(() => import("views/app-views/apps/sms"))
  },
  {
    key: "apps.accounts",
    path: `${APP_PREFIX_PATH}/apps/accounts`,
    component: React.lazy(() => import("views/app-views/apps/accounts/accounts"))
  },
  {
    key: "apps.clients",
    path: `${APP_PREFIX_PATH}/apps/clients`,
    component: React.lazy(() => import("views/app-views/apps/clients"))
  },
  {
    key: "apps.contacts",
    path: `${APP_PREFIX_PATH}/apps/contacts`,
    component: React.lazy(() => import("views/app-views/apps/contacts"))
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list"))
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting"))
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile"))
  }
];

export const homeRoutes = [

  {
    key: "lock-screen",
    path: `${AUTH_PREFIX_PATH}/lock-screen`,
    component: React.lazy(() => import("views/auth-views/authentication/lock-screen")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.homepage",
    path: `${HOME_PREFIX_PATH}/pages/homepage`,
    component: React.lazy(() => import("views/home-views/pages/homepage")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.aboutus",
    path: `${HOME_PREFIX_PATH}/pages/aboutus`,
    component: React.lazy(() => import("views/home-views/pages/aboutus")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.terms",
    path: `${HOME_PREFIX_PATH}/pages/terms`,
    component: React.lazy(() => import("views/home-views/pages/terms")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.privacy",
    path: `${HOME_PREFIX_PATH}/pages/privacy`,
    component: React.lazy(() => import("views/home-views/pages/privacy")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.register",
    path: `${HOME_PREFIX_PATH}/pages/register`,
    component: React.lazy(() => import("views/home-views/pages/register")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.subscribe",
    path: `${HOME_PREFIX_PATH}/pages/subscribe`,
    component: React.lazy(() => import("views/home-views/pages/subscribe")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.requisition",
    path: `${HOME_PREFIX_PATH}/pages/requisition`,
    component: React.lazy(() => import("views/home-views/pages/requisition")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.revenform",
    path: `${HOME_PREFIX_PATH}/pages/revenform`,
    component: React.lazy(() => import("views/home-views/pages/revenform")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.modaldisplay",
    path: `${HOME_PREFIX_PATH}/pages/modaldisplay`,
    component: React.lazy(() => import("views/home-views/pages/modaldisplay/modalfroms")),
    meta: {
      blankLayout: true
    }
  }
];
