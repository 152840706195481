const REGION = process.env.REACT_APP_CLOUD_REGION;

let publicRoutes, protectedRoutes, homeRoutes;

switch (REGION) {
  case 'ca':
    ({ publicRoutes, protectedRoutes, homeRoutes } = require('./Routes/RouteCA'));
    break;
  case 'aus':
    ({ publicRoutes, protectedRoutes, homeRoutes } = require('./Routes/RouteAUS'));
    break;
  default:
    ({ publicRoutes, protectedRoutes, homeRoutes } = require('./Routes/RouteCA'));
}

export { publicRoutes, protectedRoutes, homeRoutes };